<template>
  <div class="TwitterItem">
    <div class="my-3">
        <p>{{text}}</p>
        <p><small>{{getFormatedDate(created_at)}} - {{getFormatedTime(created_at)}} Uhr</small></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'TwitterItem',
  props: {
    text: String,
    id: String,
    created_at: String
  },
  computed: {
    ...mapGetters({
      getFormatedDate: "getFormatedDate",
      getFormatedTime: "getFormatedTime"
    }),
  },
}
</script>
