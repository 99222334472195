<template>
  <div id="Next">

    <div v-if="nextShift()">
      <div class="shift-next text-center py-4 text-shadow">
        <p class="shift-next-headline brand-color-dark text-2xl pb-2">Nächster Dienst</p>
        <p class="shift-next-countdown text-3xl" v-if="!countdownFinish">
          <span id="shift-next-countdown-days" v-if="countdown.days > 0"
            >&gt; {{ countdown.days }} Tag<span v-show="countdown.days > 1">e</span>
          </span>
          <span v-else>
            <span v-if="countdown.hours > 0">
              <span id="shift-next-countdown-hours">&gt; {{ countdown.hours }}</span
            > Stunde<span v-show="countdown.hours > 1">n</span>
            </span>
            <span v-else>
              <span id="shift-next-countdown-minutes">{{ countdown.minutes }}</span
            >:<span id="shift-next-countdown-seconds">{{ countdown.seconds }}</span>
            </span>
          </span>
        </p>
        <p class="shift-next-countdown text-2xl" v-else>
          Wir wünschen Dir einen ruhigen Dienst!
        </p>
        <p class="shift-next-date">
          {{ getWeekdayForDate(nextShift().header_date, true) }}, den
          {{ getFormatedDate(nextShift().header_date) }}
        </p>
      </div>

      <div class="content-card card-fullscreen">
        <div class="card-header text-center bg-brand card-header-grid rounded-t-lg grid grid-cols-2 sm:grid-cols-4 gap-x-0 sm:gap-x-2 gap-y-4 px-2 py-5 items-start">
          <div class="card-header-section">
            <p class="card-header-section-headline font-bold">
              <font-awesome-icon icon="asterisk" class="icon" /> Dienstnr.
            </p>
            <p class="card-header-section-text">{{ nextShift().header_nr }}</p>
          </div>
          <div class="card-header-section">
            <p class="card-header-section-headline font-bold">
              <font-awesome-icon icon="clock" class="icon" /> Dienstlage
            </p>
            <p class="card-header-section-text">
              <ServiceSituationIcon :data="nextShift()"></ServiceSituationIcon> {{ getServiceSituationLabel(nextShift()) }}
            </p>
          </div>
          <div class="card-header-section">
            <p class="card-header-section-headline font-bold">
              <font-awesome-icon icon="business-time" class="icon" /> Arbeitszeit
            </p>
            <p class="card-header-section-text">
              {{ nextShift().time_start }} bis
              {{ nextShift().time_end }} Uhr<br />
              {{ nextShift().shift_workingtime }}h
            </p>
          </div>
          <div class="card-header-section" v-if="resttimeHours">
            <p class="card-header-section-headline font-bold">
              <font-awesome-icon icon="bed" class="icon" /> Ruhezeit
            </p>
            <p class="card-header-section-text">~ {{ resttimeHours }} Stunden</p>
          </div>
        </div>
        <div class="card-content bg-card-content text-center py-2 px-2">
          <!-- <div class="alert alert-default mt-3 p-3 rounded-lg text-black bg-gray-200" v-if="irregularitiesCount > 0">
            <font-awesome-icon icon="exclamation-circle" class="icon text-blue-900 text-3xl" /> 
            <p>
              Du bist ggf. von
              <b>{{ irregularitiesCount }}</b> Unregelmäßigkeit<span
                v-if="
                  irregularitiesCount == 0 ||
                  irregularitiesCount > 1
                "
                >en</span
              >
              betroffen.<br />
              <span v-if="!irregularitiesDetails">
              <a class="link cursor-pointer text-blue-400" @click="showIrregularities()">Ansehen</a> oder
              <router-link to="../board" class="link cursor-pointer text-blue-400"
                >gesamtes Board ansehen</router-link
              >
              </span>
              <span v-else>
              </span>
            </p>
          </div> -->

          <div class="alertbox border-color-brand" v-if="irregularitiesCount > 0">
            <div class="alertbox-icon bg-brand">
              <font-awesome-icon icon="exclamation-circle" class="icon"/>
            </div>
            <div class="alertbox-content">
              <!-- <p class="alertbox-heading text-blue-500">
                <b>2 Dienste verfügbar!</b>
              </p> -->
              <p class="alertbox-maincontent">
                Du bist ggf. von
              <b>{{ irregularitiesCount }}</b> Unregelmäßigkeit<span
                v-if="
                  irregularitiesCount == 0 ||
                  irregularitiesCount > 1
                "
                >en</span
              >
              betroffen.<br />
              <!-- <span v-if="!irregularitiesDetails">
              <a class="link cursor-pointer text-blue-400" @click="showIrregularities()">Ansehen</a> oder
              <router-link to="../board" class="link cursor-pointer text-blue-400"
                >gesamtes Board ansehen</router-link
              >
              </span> -->
              <!-- <span v-else> -->
                <!-- {{irregularitiesDetails}} -->
                <!-- <span v-for="d in irregularitiesDetails.data" :key="d.id">
                  <a class="link" target="_newTab" :href="'/board/'+d.id">
                  {{d.station_a.name}} / {{d.station_b.name}}
                  ( <span v-for="line in d.lines" :key="line.id">{{line.lines_id.name}} </span>)
                  </a><br>
                </span> -->
              <!-- </span> -->
              </p>
              <p class="alertbox-hint" v-show="!irregularitiesDetails">
                <a class="link cursor-pointer text-blue-400" @click="showIrregularities()">Ansehen</a> oder
              <router-link to="../board" class="link cursor-pointer text-blue-400"
                >gesamtes Board ansehen</router-link
              >
              </p>
            </div>
          </div>

          <!-- <div
            class="alert alert-default mt-3 p-3 rounded-lg text-black bg-gray-200"
            v-if="
              nextShift() &&
              nextShift().shift[nextShift().shift.length - 1].typ == 0 &&
              nextShift().shift[nextShift().shift.length - 1].end_direction == ''
            "
          >
          <font-awesome-icon icon="warehouse" class="icon text-blue-900 text-3xl" />
            <p>
              Dieser Dienst endet auf einem Betriebshof oder in einer
              Abstellanlage.
            </p>
          </div> -->



          <div class="alertbox border-color-brand" v-if="
              nextShift() &&
              nextShift().shift[nextShift().shift.length - 1].typ == 0 &&
              nextShift().shift[nextShift().shift.length - 1].end_direction == ''
            ">
            <div class="alertbox-icon bg-brand">
              <font-awesome-icon icon="warehouse" class="icon"/>
            </div>
            <div class="alertbox-content">
              <!-- <p class="alertbox-heading text-blue-500">
                <b>2 Dienste verfügbar!</b>
              </p> -->
              <p class="alertbox-maincontent">
                Dieser Dienst endet auf einem Betriebshof oder in einer
              Abstellanlage.
              </p>
              <!-- <p class="alertbox-hint">
                Wähle einen vorgeschlagenen Dienst aus oder stelle Deinen Dienst selber in die Tauschbörse.
              </p> -->
            </div>
          </div>




        </div>

          
          
        
        <div class="card-content bg-card-content rounded-b-lg text-center py-2 px-2">
          
          <!-- <router-link to="/tracker" class="text-green-400 font-bold text-lg text-center mb-2 animate-pulse">JUST IN TIME</router-link> -->
          
          <ShiftDetail :data="nextShift()"></ShiftDetail>
        </div>

        <!-- <div class="flex flex-col sm:flex-row">
          <div class="w-full h-96" >
            <a class="twitter-timeline" data-chrome="nofooter" data-lang="de" data-height="384" data-dnt="true" data-theme="dark" href="https://twitter.com/KVB_Info?ref_src=twsrc%5Etfw">Tweets by KVB_Info</a>
          </div>
        </div> -->


          <!-- <div class="w-full h-96" >
            <Map :geojson="geojson"></Map>
          </div> -->
        
      </div>


      <div id="irregularitiesBoxToScroll">
        <div v-if="irregularitiesDetails">
          <hr class="space my-8">
          <h3><span class="brand-color-dark text-xl font-bold"><font-awesome-icon icon="exclamation-circle" class="icon" /> <b>{{ irregularitiesCount }}</b> Unregelmäßigkeit<span v-if="irregularitiesCount == 0 || irregularitiesCount > 1 ">en</span></span><br><small class="text-sm">Weitere Informationen findest Du im <router-link to="/board" class="link cursor-pointer text-blue-400">Board</router-link></small></h3>
          <div class="content-card-container grid grid-cols-1 lg:grid-cols-2 gap-x-2 sm:gap-x-2 gap-y-4 my-3 items-start">
            <SlowMovingLocationItem :data="d" :key="d.id" v-for="d in irregularitiesSpeedlimits.data"></SlowMovingLocationItem>
          </div>
          <div class="content-card-container grid grid-cols-1 gap-3">
            <FileItem :data="d" :key="d.id" v-for="d in irregularitiesConstructionsites.data"></FileItem>
          </div>
          <div class="content-card-container grid grid-cols-1 gap-3">
            <FileItem :data="d" :key="d.id" v-for="d in irregularitiesInformations.data"></FileItem>
          </div>
        </div>
      </div>

    </div>

    <NoDataBox v-else headline="Kein nächster Dienst verfügbar"><router-link to="../syncmanager" class="link">Synchronisiere</router-link> regelmäßig Deine Dienste mit uns, damit wir Dir Deinen Dienstplan zu senden und Statistiken für Dich erstellen können.</NoDataBox>

    <!-- <div class="flex flex-col sm:flex-row">
      <TwitterConsent />
    </div> -->

    <div class="bg-gray-900 p-8 rounded-md mt-2">
      <h3><span class="brand-color-dark text-xl font-bold"><font-awesome-icon icon="exclamation-circle" class="icon" /> Aktuelle Meldungen</span></h3>
      <p v-show="twitterFeed.count == 0">Daten werden geladen...</p>
      <div class="text-left divide-y-2 divide-gray-500  mt-2">
        <TwitterItem v-for="item in twitterFeed" :key="item.id" :text="item.text" :id="item.id" :created_at="item.created_at"></TwitterItem>
      </div>
      <p><small class="text-sm text-gray-400">Diese Informationen werden durch <a href="https://twitter.com/kvb_info" class="link" target="_newTab">Twitter</a> bereitgestellt.</small></p>
    </div>
    


    
  </div>
</template>


<script>
import { mapGetters } from "vuex";


// import osmtogeojson from '@/assets/osmtogeojson.js'
import axios from 'axios';


import ServiceSituationIcon from "@/components/ServiceSituationIcon";

import ShiftDetail from "@/components/ShiftDetail";

import SlowMovingLocationItem from "@/components/SlowMovingLocationItem";
import FileItem from "@/components/FileItem";

// import Map from "@/components/MapTracker";

import NoDataBox from "@/components/NoDataBox";

import TwitterConsent from "@/components/TwitterConsent";
import TwitterItem from "@/components/TwitterItem";

export default {
  name: "Next",
  components: {
    ShiftDetail,
    SlowMovingLocationItem,
    FileItem,
    ServiceSituationIcon,
    // Map,
    NoDataBox,
    TwitterConsent,
    TwitterItem
  },
  data() {
    return {
      countdown: {
        days: "0",
        hours: "00",
        minutes: "00",
        seconds: "00",
      },
      countdownInterval: null,
      countdownFinish: false,
      isLoadingIrregularities: false,
      irregularitiesSpeedlimits: null,
      irregularitiesConstructionsites: null,
      irregularitiesInformations: null,
      irregularitiesCount: 0,
      irregularities: false,
      irregularitiesLines: [],
      irregularitiesDetails: false,
      resttimeHours: false,

      geojson: {
        "type": "FeatureCollection",
        "features": [
        ]
      },

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],

      twitterFeed: []
    };
  },
  methods: {

    cleanLinesFromShift(val) {
      var ns = val;

      if (!ns) {
        return [];
      }

      var lines = [];

      for (let index = 0; index < ns.shift.length; index++) {
        if(ns.shift[index].line) {
          if (lines.includes(ns.shift[index].line) === false) {
            lines.push(ns.shift[index].line);
          }
        }
      }

      return lines

    },

    /*
    getMapRelationsForShift(val) {
      var ns = val;

      if (!ns) {
        return;
      }

      var lines = this.cleanLinesFromShift(val);

      axios.get('https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];relation(36132);relation(r);(way(r)->.a;node(r););out geom;') 
      .then(result => {
        //console.log(result)

        var d = osmtogeojson(result.data, {flatProperties:false});
        for (let index = 0; index < d.features.length; index++) {
          d.features[index].relation = 36132;
        }
        this.geojson["features"].push(d);
        console.log(this.geojson)
      }).catch(function (error) {
        console.log("Error overpass-api:", error);
      });

      
      axios.get('https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];relation(2026288);relation(r);(way(r)->.a;node(r););out geom;') 
      .then(result => {
        console.log(result)
        var d = osmtogeojson(result.data, {flatProperties:false});
        for (let index = 0; index < d.features.length; index++) {
          d.features[index].relation = 2026288;
        }
        this.geojson["features"].push(d);
      }).catch(function (error) {
        console.log("Error overpass-api:", error);
      });
    },
    */

    getIrregularitiesForNextShift(val) {
      var ns = val;

      if (!ns) {
        return;
      }

      this.irregularitiesLines = this.cleanLinesFromShift(val);
      
      var promise1 = this.$store.dispatch("fetchSpeedlimitsForLines", { saveData: true, lines: this.irregularitiesLines });
      var promise2 = this.$store.dispatch("fetchBoardConstructionsitesForLines", { saveData: true, lines: this.irregularitiesLines });
      var promise3 = this.$store.dispatch("fetchBoardInformationsForLines", { saveData: true, lines: this.irregularitiesLines });

      Promise.all([promise1, promise2, promise3]).then((values) => {
        this.irregularitiesCount = 0;
        
        if(values[0].status) {
          this.irregularitiesSpeedlimits = values[0].result;
          this.irregularitiesCount += values[0].result.data.length
        }

        if(values[1].status) {
          this.irregularitiesConstructionsites = values[1].result;
          this.irregularitiesCount += values[1].result.data.length
        }

        if(values[2].status) {
          this.irregularitiesInformations = values[1].result;
          this.irregularitiesCount += values[2].result.data.length
        }
      });

    },
    calculateNextShiftCountdown(val) {
      this.setNextShiftCountdown();
      this.countdownInterval = setInterval(this.setNextShiftCountdown, 1000);
    },
    setNextShiftCountdown() {
      var val = this.nextShift();

      if (!val || val.shift.lenght <= 0) {
        return false;
      }

      var s = val.shift[0].start_time;
      var h = s.split(":")[0];
      var m = s.split(":")[1];

      var countDownDate = new Date(val.header_date);
      countDownDate.setHours(h);
      countDownDate.setMinutes(m);

      countDownDate = countDownDate.getTime();

      var now = new Date().getTime();

      var distance = countDownDate - now;

      this.countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

      this.countdown.minutes = this.makeTwoDigit(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      this.countdown.seconds = this.makeTwoDigit(
        Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance < 0) {
        clearInterval(this.countdownInterval);
        this.countdownFinish = true;
      }
    },
    makeTwoDigit(num) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    calculateResttime(ns) {
      if (!ns) {
        return;
      }

      if (this.shifts.indexOf(ns) == -1) {
        return;
      }


      for (let i = (this.shifts.indexOf(ns) + 1); i < this.shifts.length; i++) {
        var doc = this.shifts[i];

        // console.log(doc);

        if (doc.shift.length != 0) {
          var time = doc.time_start.split(":");

          var timeNs = ns.time_end.split(":");

          timeNs[1] = timeNs[1].replace("+","");

          // console.log("start", time);
          // console.log("timeNs", timeNs);

          var shiftNextDate = new Date(ns.header_date);
          if (timeNs < time) {
            shiftNextDate.setDate(shiftNextDate.getDate() + 1);
          }

          var a = new Date(doc.header_date).setHours(+time[0], +time[1]);
          var b = shiftNextDate.setHours(+timeNs[0], +timeNs[1]);

          // console.log(new Date(a));
          // console.log(new Date(b));
          this.resttimeHours = Math.floor(Math.abs(a - b) / 36e5);

          return true;
        }

        return false
      }
    },

    showIrregularities() {
      this.irregularitiesCount = 0;
      var promise1 = this.$store.dispatch("fetchSpeedlimitsForLines", { saveData: false, lines: this.irregularitiesLines });
      var promise2 = this.$store.dispatch("fetchBoardConstructionsitesForLines", { saveData: false, lines: this.irregularitiesLines });
      var promise3 = this.$store.dispatch("fetchBoardInformationsForLines", { saveData: false, lines: this.irregularitiesLines });

      Promise.all([promise1, promise2, promise3]).then((values) => {
        if(values[0].status) {
          this.irregularitiesSpeedlimits = values[0].result;
          this.irregularitiesCount += values[0].result.data.length
        }

        if(values[1].status) {
          this.irregularitiesConstructionsites = values[1].result;
          this.irregularitiesCount += values[1].result.data.length
        }

        if(values[2].status) {
          this.irregularitiesInformations = values[2].result;
          this.irregularitiesCount += values[2].result.data.length
        }

        this.irregularitiesDetails = true;

        var element = document.getElementById("irregularitiesBoxToScroll");
        element.scrollIntoView();
      });
    },

    getTwitterFeed() {
      this.$store.dispatch("fetchTwitterFeed").then((v) => {
        // console.log(v);
        this.twitterFeed = []
        this.twitterFeed = v.result;
      });
    }
  },
  mounted() {
    var ns = this.nextShift();
    this.calculateNextShiftCountdown(ns);
    this.getIrregularitiesForNextShift(ns);
    this.calculateResttime(ns);

    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    // document.head.appendChild(recaptchaScript)

    this.getTwitterFeed()
  },
  watch: {
    shifts: function (val) {
      var ns = this.nextShift();
      this.calculateNextShiftCountdown(ns);
      this.getIrregularitiesForNextShift(ns);
      this.calculateResttime(ns);
    },
  },
  computed: {
    ...mapGetters({
      getFormatedDate: "getFormatedDate",
      getWeekdayForDate: "getWeekdayForDate",
      getServiceSituationLabel: "getServiceSituationLabel",
      nextShift: "getNextShifts",
      shifts: "getShiftsForCurrentMonth",
    }),
  },
};
</script>
