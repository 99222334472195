<template>
  <div class="TwitterConsent">
    <div class="text-center bg-gray-800 p-8 rounded-md">
        <!-- <font-awesome-icon icon="tools" class="icon text-4xl brand-color-dark" /><br><br> -->
        <p class="brand-color-dark text-2xl"><b>Twitter Datenschutz Consent</b></p>
        <!-- <p class="brand-color-dark font-bold"><b>Diese Funktion ist für Deinen Verkehrsbetrieb (noch) nicht verfügbar.</b></p> -->
        <p>Wir nutzen an dieser Stelle ein Twitter-Widget, dass Dir den Feed von Deinem Verkehrsbetrieb anzeigt. Twitter sammelt und verarbeitet in diesem Rahmen Informationen über Dich, über die wir als StadtbahnfahrerClub keinen Einfluss und keine Kontrolle haben. Es gelten für das Twitter-Widget die <a href="https://twitter.com/privacy" target="_newTab" class="link">Twitter Datenschutzbestimmungen</a> und die <a href="https://help.twitter.com/de/rules-and-policies/twitter-cookies" target="_newTab" class="link">Twitter Cookie-Richtlinien</a>.</p>
        <div class="flex flex-col sm:w-1/3 w-full mx-auto gap-2 mt-4">
          <button class="button-filled group relative w-full flex justify-center py-2 px-4 font-medium rounded-md text-white hover:bg-blue-400">
            Zustimmen
          </button>
          <button class="font-medium rounded-md text-blue-400 hover:text-white">
            Ablehnen
          </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwitterConsent',
  props: {
    name: String
  }
}
</script>
